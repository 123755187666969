import type { UseFetchOptions } from "nuxt/app";
import { CustomSession } from "~/types/Auth";

export const useDatAdsApiFetch = async <T>(
  url: string | (() => string),
  options: UseFetchOptions<T> = {},
) => {
  const {
    public: { apiBaseUrl },
  } = useRuntimeConfig();
  const { data, getSession } = useAuth();
  if (data.value == null) {
    // eslint-disable-next-line no-console
    console.warn("Session not found");
    return useFetch(url, {
      ...options,
      baseURL: apiBaseUrl,
    });
  }
  let sessionWithToken = data.value as CustomSession;
  if (new Date(sessionWithToken.expires).getTime() < Date.now()) {
    // eslint-disable-next-line no-console
    console.warn(
      "[useDatAdsApiFetch] Access token has expired, trying to refresh it",
      sessionWithToken.expires,
    );
    sessionWithToken = await getSession();
  }
  if (sessionWithToken.access_token == null) {
    throw new Error("Access token not found");
  }
  return useFetch(url, {
    ...options,
    baseURL: apiBaseUrl,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${sessionWithToken.access_token}`,
    },
  });
};
