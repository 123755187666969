import { MetricsFilter } from "./Metrics";

export enum Provider {
  META = "META",
  TIKTOK = "TIKTOK",
  GOOGLE = "GOOGLE",
  ADTRIBUTE = "ADTRIBUTE",
  GOOGLE_SHEETS = "GOOGLE_SHEETS",
  HYROS = "HYROS",
}

export const AdProvider = [Provider.META, Provider.TIKTOK, Provider.GOOGLE];

export const EnrichingProvider = [
  Provider.ADTRIBUTE,
  Provider.GOOGLE_SHEETS,
  Provider.HYROS,
];

export const isEnrichingProvider = (provider: Provider) =>
  EnrichingProvider.includes(provider);

export const isAdProvider = (provider: Provider) =>
  AdProvider.includes(provider);

export interface Config<T> {
  id: T;
  displayName: string;
  enabled: boolean;
  featureFlag?: FeatureFlag;
}

export interface ConfigWithLogo<T> extends Config<T> {
  logo: string;
}

export interface ConfigWithGroup<T> extends Config<T> {
  group: string;
}

export interface ConfigWithDescription<T> extends Config<T> {
  description: string;
}

export interface ConfigWithDescriptionMaybe<T> extends Config<T> {
  description?: string;
}

export type ConfigWithGroupAndLogo<T> = ConfigWithGroup<T> & ConfigWithLogo<T>;

export type ConfigWithGroupAndLogoAndDescription<T> = ConfigWithGroup<T> &
  ConfigWithLogo<T> &
  ConfigWithDescription<T>;

export type ConfigWithGroupAndDescriptionMaybe<T> = ConfigWithGroup<T> &
  ConfigWithDescriptionMaybe<T>;

export type Toast = {
  type: "info" | "warning" | "success" | "error";
  message: string;
};

export type TimeoutableToast = Toast & { id: string; timestamp: Date };

export type ValidationRule =
  | "url"
  | "email"
  | "phoneNumber"
  | "required"
  | "decimal"
  | "min"
  | "max"
  | "minLength"
  | "checkbox";

export type Validator = {
  validator: (val: any, prop?: any) => boolean;
  message: string;
};

export type Step = {
  id: string | number;
  name: string;
  href: string;
  status: "complete" | "current" | "upcoming";
};

export interface Query<T> {
  lt?: T | undefined | null;
  gt?: T | undefined | null;
  lte?: T | undefined | null;
  gte?: T | undefined | null;
  eq?: T | undefined | null;
  neq?: T | undefined | null;
  contains?: T | undefined | null;
  containsnot?: T | undefined | null;
  in?: T[] | undefined | null;
}

export type QueryOption = keyof Query<any>;

/**
 * Last → yesterday
 * This → today
 * Week → Start of week until today/yesterday
 * Month → Start of month until today/yesterday
 */
export enum Timeframe {
  today = "today", // Today
  yesterday = "yesterday", // Yesterday
  three_days = "three_days", // Last 3 days
  seven_days = "seven_days", // Last 7 days
  thirty_days = "thirty_days", // Last 30 days
  ninety_days = "ninety_days", // Last 90 days
  one_hundred_eighty_days = "one_hundred_eighty_days", // Last 180 days
  this_one_week = "this_one_week", // This week
  one_week = "one_week", // Last week
  two_weeks = "two_weeks", // Last 2 weeks
  four_weeks = "four_weeks", // Last 4 weeks
  this_one_month = "this_one_month", // This month
  one_month = "one_month", // Last month
  three_months = "three_months", // Last 3 month
  six_months = "six_months", // Last 6 month
  twelve_months = "twelve_months", // Last 12 month
  all_time = "all_time", // All time
  previous_period = "previous_period", // Previous period
}

export enum GroupBy {
  NONE = "NONE",
  ADNAME = "ADNAME",
  LANDING_PAGE = "LANDING_PAGE",
  HEADLINE = "HEADLINE",
  DESCRIPTION = "DESCRIPTION",
  POST_ID = "POST_ID",
  CREATIVE_ID = "CREATIVE_ID",
  DYNAMIC_CREATIVE = "DYNAMIC_CREATIVE",
  CLIENT = "CLIENT",
  AD_FORMAT = "AD_FORMAT",
  AD_GROUP_ID = "AD_GROUP_ID",
  AD_CAMPAIGN_ID = "AD_CAMPAIGN_ID",
}

export enum Sort {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SortBy {
  PRIMARY_METRIC = "PRIMARY_METRIC",
  AD_CREATION_DATE = "AD_CREATION_DATE",
  CUSTOM_ORDER = "CUSTOM_ORDER",
}

export type EmptySubmissionFilter = {
  logicalOperator: LogicalOperator;
  property: string | null;
  queryOption: QueryOption | null;
  value: string;
};

export type FilledSubmissionFilter = {
  logicalOperator: LogicalOperator;
  property: string;
  queryOption: QueryOption;
  value: string;
};

export type SubmissionFilter = EmptySubmissionFilter | FilledSubmissionFilter;

export type LogicalOperator = "AND" | "OR";

export enum AdFormat {
  VIDEO = "VIDEO",
  IMAGE = "IMAGE",
  CAROUSEL = "CAROUSEL",
  MIXED = "MIXED",
}

export type FeatureFlag = keyof typeof FeatureFlags;

const FeatureFlags = {
  "inspiration-board_2024-01-01": "inspiration-board_2024-01-01",
  "ad-details_2023-01-04": "ad-details_2023-01-04",
  "testing-log-best-group_2023-01-05": "testing-log-best-group_2023-01-05",
  "sidebar-folder-structure_2024-01-05": "sidebar-folder-structure_2024-01-05",
  "warning-header": "warning-header",
  "testing-log-drag-drop-setup_2024-01-22":
    "testing-log-drag-drop-setup_2024-01-22",
  "inspiration-board-upload-assets_2024-02-19":
    "inspiration-board-upload-assets_2024-02-19",
  "usage-banner": "usage-banner",
  "google-ads_2024-03-13": "google-ads_2024-03-13",
  "testing-log-as-report_2024-03-27": "testing-log-as-report_2024-03-27",
  "dynamic-creatives_2024-04-06": "dynamic-creatives_2024-04-06",
  "attribution-window_2024-05-22": "attribution-window_2024-05-22",
  "adtribute_2024-05-23": "adtribute_2024-05-23",
  "metrics-outbound-clicks_2024-07-03": "metrics-outbound-clicks_2024-07-03",
  "custom-metrics_2024-07-09": "custom-metrics_2024-07-09",
  "launch-cockpit_2024-07-15": "launch-cockpit_2024-07-15",
  "datads-creative-strategist_2024-08-05":
    "datads-creative-strategist_2024-08-05",
  "creative-comparison-custom-order_2024-08-14":
    "creative-comparison-custom-order_2024-08-14",
  "csv-export_2024-08-15": "csv-export_2024-08-15",
  "google-sheets_2024-08-16": "google-sheets_2024-08-16",
  "launch-report_2024-08-20": "launch-report_2024-08-20",
  "tagging_2024-09-02": "tagging_2024-09-02",
  "comparison-categories_2024-09-24": "comparison-categories_2024-09-24",
  "metrics-engagement_2024-10-23": "metrics-engagement_2024-10-23",
  "metric-scores_2024-10-29": "metric-scores_2024-10-29",
  "hyros_2024-11-02": "hyros_2024-11-02",
};

// To safe usage hard code the values
export const isEnabledOnSharePage: Record<FeatureFlag, boolean> = {
  "inspiration-board_2024-01-01": true,
  "ad-details_2023-01-04": true,
  "testing-log-best-group_2023-01-05": true,
  "sidebar-folder-structure_2024-01-05": true,
  "warning-header": false,
  "testing-log-drag-drop-setup_2024-01-22": true,
  "inspiration-board-upload-assets_2024-02-19": false,
  "usage-banner": false,
  "google-ads_2024-03-13": true,
  "testing-log-as-report_2024-03-27": false,
  "dynamic-creatives_2024-04-06": true,
  "attribution-window_2024-05-22": true,
  "adtribute_2024-05-23": true,
  "metrics-outbound-clicks_2024-07-03": true,
  "custom-metrics_2024-07-09": true,
  "launch-cockpit_2024-07-15": true,
  "datads-creative-strategist_2024-08-05": false,
  "creative-comparison-custom-order_2024-08-14": true,
  "csv-export_2024-08-15": false,
  "google-sheets_2024-08-16": true,
  "launch-report_2024-08-20": false,
  "tagging_2024-09-02": true,
  "comparison-categories_2024-09-24": false,
  "metrics-engagement_2024-10-23": true,
  "metric-scores_2024-10-29": true,
  "hyros_2024-11-02": true,
};

// To safe usage hard code the values
export const isEnabledOnLocalDev: Record<FeatureFlag, boolean> = {
  "inspiration-board_2024-01-01": true,
  "ad-details_2023-01-04": true,
  "testing-log-best-group_2023-01-05": true,
  "sidebar-folder-structure_2024-01-05": true,
  "warning-header": false,
  "testing-log-drag-drop-setup_2024-01-22": true,
  "inspiration-board-upload-assets_2024-02-19": true,
  "usage-banner": false,
  "google-ads_2024-03-13": true,
  "testing-log-as-report_2024-03-27": true,
  "dynamic-creatives_2024-04-06": true,
  "attribution-window_2024-05-22": true,
  "adtribute_2024-05-23": true,
  "metrics-outbound-clicks_2024-07-03": true,
  "custom-metrics_2024-07-09": true,
  "launch-cockpit_2024-07-15": true,
  "datads-creative-strategist_2024-08-05": true,
  "creative-comparison-custom-order_2024-08-14": true,
  "csv-export_2024-08-15": true,
  "google-sheets_2024-08-16": true,
  "launch-report_2024-08-20": true,
  "tagging_2024-09-02": true,
  "comparison-categories_2024-09-24": true,
  "metrics-engagement_2024-10-23": true,
  "metric-scores_2024-10-29": true,
  "hyros_2024-11-02": true,
};

export const ReportingLevels = [
  "ad_group_ad",
  "ga_source_medium",
  "ad_group_ad_creative",
] as const;
export type ReportingLevel = (typeof ReportingLevels)[number];

export enum AttributionWindow {
  DEFAULT = "DEFAULT", // Defined per adset in platform
  ONE_DAY_CLICK = "ONE_DAY_CLICK",
  SEVEN_DAY_CLICK = "SEVEN_DAY_CLICK",
  TWENTY_EIGHT_DAY_CLICK = "TWENTY_EIGHT_DAY_CLICK",
  ONE_DAY_VIEW = "ONE_DAY_VIEW",
  SEVEN_DAY_VIEW = "SEVEN_DAY_VIEW",
  TWENTY_EIGHT_DAY_VIEW = "TWENTY_EIGHT_DAY_VIEW",
  ONE_DAY_EV = "ONE_DAY_EV",
  SEVEN_DAY_CLICK_OR_ONE_DAY_VIEW = "SEVEN_DAY_CLICK_OR_ONE_DAY_VIEW",
}

export enum ViewType {
  grid = "grid",
  linechart = "linechart",
  barchart = "barchart",
  table = "table",
}

export enum ReportType {
  TOPCREATIVE = "TOPCREATIVE",
  COMPARISON = "COMPARISON",
  TESTING_LOG = "TESTING_LOG",
  LAUNCH = "LAUNCH",
}

export type NavigationElement = {
  id: string;
  name: string;
  href: string;
  icon: string;
  current: boolean;
  duplicateHref: string;
  type: string;
};

export interface SubmissionGroup {
  id: number;
  uuid: string;
  title: string;
  filter: MetricsFilter[][] | null;
  total: number;
  isCreated: boolean;
  isLoading: boolean;
  categoryId: number | null;
}
