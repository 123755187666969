/* eslint-disable no-console */
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { sentryDsn, containerAppsEnvironment, release },
  } = useRuntimeConfig();
  const { getAnalyticsUser } = useSegment();
  const { appUserMe } = useAppUserStore();
  if (!appUserMe) {
    return;
  }
  const { userId, traits: userTraits } = getAnalyticsUser(appUserMe);

  if (!sentryDsn) {
    console.warn(
      `[sentry]: sentryDsn is empty - cannot initialise sentry plugin`,
    );
    return;
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentryDsn,
    environment: containerAppsEnvironment,
    integrations: [],
    release,
    initialScope: {
      user: {
        id: userId,
        ...userTraits,
      },
    },
  });
});
